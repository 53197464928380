<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>巡检记录</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>企业名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>
      <div class="selectCol">
        <span>巡检状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>整改状态</span>
        <el-select v-model="value1" placeholder="请选择">
          <el-option
            v-for="item in optionss"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>所属平台</span>
        <el-select clearable v-model="platId" placeholder="请选择">
          <el-option v-for="item in platList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>开始时间</span>
        <el-date-picker
          v-model="start_time"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptionsStart"
          clearable
        ></el-date-picker>
      </div>
      <div class="selectCol">
        <span>结束时间</span>
        <el-date-picker
          v-model="end_time"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptionsEnd"
          clearable
        ></el-date-picker>
      </div>
    </el-row>
    <el-row class="selectRow">
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="output" class="checkOutBtn" style="margin-left:10px">巡查记录导出</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="id" label="编号" width="100"></el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称">
          <template slot-scope="scope">
            <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="danger_name" label="风险点名称">
          <template slot-scope="scope">
            <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="safety_inspection_name" label="班次名称">
          <template slot-scope="scope">
            <span
              :title="scope.row.safety_inspection_name"
              class="overflowDoit"
            >{{scope.row.safety_inspection_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="巡检人">
          <template slot-scope="scope">
            <span :title="scope.row.user_name" class="overflowDoit">{{scope.row.user_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="巡检状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1" style="color:blue">正常</span>
            <span v-if="scope.row.status==2" style="color:red">异常</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="add_time" label="巡检时间">
          <template slot-scope="scope">
            <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inspection_status_show" label="整改状态"></el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row)" class="tableBtn">巡检详情</el-button>
            <el-button v-if="scope.row.status==2" @click="changeHistory(scope.row)" class="tableBtn modifyBtn">整改记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 巡检记录巡检详情开始(整改记录点击查看详情) -->
    <!-- :title="`整改详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`整改详情(${dangerName})`"
      class="riskDia checkDetails"
      :visible.sync="dialogdh"
      width="50%"
      :before-close="dialogdhClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData1" label-width="120px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData1.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="班次名称">
            <el-input style="width:70%" disabled v-model="formData1.safety_inspection_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检人">
            <el-input style="width:70%" disabled v-model="formData1.user_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检状态">
            <el-input
              style="width:70%"
              :class="formData1.status==1?'isBlue':'isRed'"
              disabled
              v-model="formData1.status==1?'正常':'异常'"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检备注" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData1.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgCheckList"
              v-for="item in changeImgCheckList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoCheckList"
              v-for="item in changeVideoCheckList"
              controls
              autoplay
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
          <el-form-item label="巡检时间">
            <el-input style="width:70%" disabled v-model="formData1.add_time"></el-input>
          </el-form-item>
          <el-form-item label="整改指派人">
            <el-input style="width:70%" disabled v-model="formData1.assign_username"></el-input>
          </el-form-item>
          <el-form-item label="指派完成时间">
            <el-input style="width:70%" disabled v-model="formData1.rectify_overtime"></el-input>
          </el-form-item>
          <el-form-item label="整改人">
            <el-input style="width:70%" disabled v-model="formData1.rectify_username"></el-input>
          </el-form-item>
          <el-form-item label="整改时间">
            <el-input style="width:70%" disabled v-model="formData1.rectify_time"></el-input>
          </el-form-item>
          <el-form-item label="整改备注" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData1.rectify_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="整改图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgList.length>0"
              v-for="item in changeImgList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoResetList.length>0"
              v-for="item in changeVideoResetList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
          <el-form-item label="复查人">
            <el-input style="width:70%" disabled v-model="formData1.review_username"></el-input>
          </el-form-item>
          <el-form-item label="复查时间">
            <el-input style="width:70%" disabled v-model="formData1.review_time"></el-input>
          </el-form-item>
          <el-form-item label="复查备注" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData1.review_remarks"
            ></el-input>
          </el-form-item>
          <el-form-item label="复查图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="changeImgResetList.length>0"
              v-for="item in changeImgResetList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="changeImgResetList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="changeVideoResetList.length>0"
              v-for="item in changeVideoResetList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="changeVideoCheckList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 巡检记录巡检详情结束 -->

    <!-- 整改记录dia开始 -->
    <!-- :title="`整改记录(${userName})`" -->
    <el-dialog
      :title="`整改记录`"
      class="riskDia"
      :visible.sync="dialogHistory"
      width="80%"
      :before-close="historyDiaClose"
    >
      <el-row class="tableRow">
        <el-table border :data="tableData1" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="assign_username" label="指派人">
            <template slot-scope="scope">
              <span
                :title="scope.row.assign_username"
                class="overflowDoit"
              >{{scope.row.assign_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rectify_username" label="整改人">
            <template slot-scope="scope">
              <span
                :title="scope.row.rectify_username"
                class="overflowDoit"
              >{{scope.row.rectify_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="review_username" label="复查人">
            <template slot-scope="scope">
              <span
                :title="scope.row.review_username"
                class="overflowDoit"
              >{{scope.row.review_username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status_show" label="整改状态">
            <template slot-scope="scope">
              <span :title="scope.row.status_show" class="overflowDoit">{{scope.row.status_show}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rectify_overtime" label="整改规定时间">
            <template slot-scope="scope">
              <span
                :title="scope.row.rectify_overtime"
                class="overflowDoit"
              >{{scope.row.rectify_overtime}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="checkOutDetails(scope.row)" class="tableBtnRisk">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage1"
          :page-sizes="[3, 5, 10, 15, 20]"
          :page-size="pageSize1"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount1"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 整改记录dia结束 -->

    <!-- 巡检详情dia开始 -->
    <!-- :title="`巡检记录巡检详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`巡检记录巡检详情(${dangerName})`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="50%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="120px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="班次名称">
            <el-input style="width:70%" disabled v-model="formData.safety_inspection_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检人">
            <el-input style="width:70%" disabled v-model="formData.user_name"></el-input>
          </el-form-item>
          <el-form-item label="巡检状态">
            <el-input
              style="width:70%"
              :class="formData.status==1?'isBlue':'isRed'"
              disabled
              v-model="formData.status==1?'正常':'异常'"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检备注" prop="desc">
            <el-input
              style="width:88%"
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡检图片或视频" prop="desc" style="padding-top:10px;">
            <img
              v-if="detailImgList.length>0"
              v-for="item in detailImgList"
              @click="showPic(item)"
              style="width: 220px;height: 130px; margin-right:10px;"
              :src="item"
              alt
            />
            <img
              v-if="detailImgList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noPic.png"
              alt
            />
            <!-- controls="controls" -->
            <video
              v-if="detailVideoList.length>0"
              v-for="item in detailVideoList"
              style="width: 220px;height: 130px; object-fit:fill"
              :src="item"
              controls
              autoplay
              @click="showVideo(item)"
            >
              您的浏览器不支持
              video 标签。
            </video>
            <img
              v-if="detailVideoList.length==0"
              style="width: 220px;height: 130px; margin-right:10px;"
              src="../../assets/images/noVid.png"
              alt
            />
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 巡检详情dia结束 -->

    <!-- 图片dia开始 -->
    <el-dialog
      class="riskDia imgDia"
      :visible.sync="imgDia"
      width="50%"
      :before-close="imgDiaClose"
    >
      <el-row class="tableRow">
        <img :src="showImgUrl" alt />
      </el-row>
    </el-dialog>
    <!-- 图片dia结束 -->
    <!-- 图片dia开始 -->
    <el-dialog
      class="riskDia videoDia"
      :visible.sync="videoDia"
      width="60%"
      :before-close="videoDiaClose"
    >
      <el-row class="tableRow">
        <video controls="controls" style=" object-fit:fill" :src="showVideoUrl" @click="showVideo">
          您的浏览器不支持
          video 标签。
        </video>
      </el-row>
    </el-dialog>
    <!-- 图片dia结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      platId: '',
      platList: [],
      token: '',
      userName: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      // 巡检详情
      dialogDetail: false,
      // 整改记录
      dialogHistory: false,
      // 点击整改记录查看详情
      dialogdh: false,
      imgDia: false,
      videoDia: false,
      dangerName: '',
      formData: {},
      formData1: {},
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '正常',
        },
        {
          value: 2,
          label: '异常',
        },
      ],
      optionss: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '正常',
        },
        {
          value: 1,
          label: '待整改',
        },
        {
          value: 2,
          label: '待复查',
        },
        {
          value: 3,
          label: '待审批',
        },
        {
          value: 4,
          label: '已通过',
        },
        {
          value: 5,
          label: '未通过',
        },
      ],
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now() - 8.64e6 //如果没有后面的-8.64e6就是不可以选择今天的
      //   },
      // },
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.end_time) {
            return (
              time.getTime() > new Date(this.end_time).getTime() ||
              time.getTime() > Date.now() - 8.64e6
            )
          } else {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.start_time) {
            return (
              time.getTime() < new Date(this.start_time).getTime() - 86400000 ||
              time.getTime() > Date.now() - 8.64e6
            )
          } else {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
      },
      value: '',
      value1: '',
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
      tableData: [],
      currentPage1: 1,
      pageSize1: 10,
      totalCount1: 0,
      tableData1: [],
      // !巡检详情图片视频
      detailImgList: [],
      detailVideoList: [],
      // 整改记录--巡检图片或视频
      changeVideoCheckList: [],
      changeImgCheckList: [],
      // 整改记录--整改图片或视频
      changeVideoList: [],
      changeImgList: [],
      // 整改记录--复查图片或视频
      changeVideoResetList: [],
      changeImgResetList: [],
      checkListId: '',
      historyId: '',
      formData1: {},
      showImgUrl: '',
      showVideoUrl: '',
      historyisMine: '',
      start_time: '',
      end_time: '',
    }
  },
  methods: {
    // 导出
    async output() {
      console.log(
        '!this.start_time || !this.end_time',
        !this.start_time || !this.end_time
      )
      if (!this.start_time || !this.end_time) {
        const isDelete = await this.$confirm(
          '导出默认为当月巡查记录,如有需要请选择时间',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((error) => error)
        // 如果用户点记得是取消操作
        if (isDelete === 'cancel') return this.$message('取消导出操作')
        // 如果用户点击的是删除
        if (isDelete === 'confirm') {
          // 发送删除请求
          this.outPutSubmit()
        }
      } else if (this.start_time || this.end_time) {
        let d_start_time = new Date(this.start_time)
        let d_end_time = new Date(this.end_time)
        let t_start_time = d_start_time.getTime(d_start_time)
        let t_end_time = d_end_time.getTime(d_end_time)

        console.log('start_time', this.start_time)
        console.log('end_time', this.end_time)
        console.log('t_start_time', t_start_time)
        console.log('t_end_time', t_end_time)
        if (
          parseInt(Math.abs(t_start_time - t_end_time) / 1000 / 60 / 60 / 24) >
          30
        ) {
          return this.$message.error('导出内容不能超过30天')
        }
        this.outPutSubmit()
      } else {
        this.outPutSubmit()
      }
    },
    async outPutSubmit() {
      window.open(`http://anquan.sxpz.vip/GovApi/SafetyInspectionRecord/output_down?access_token=${this.token}&company_name=${this.companyName}&status=${this.value}&inspection_status=${this.value1}&platform_id=${this.platId}&start_time=${this.start_time}&end_time=${this.end_time}`)
      // let param = new URLSearchParams()
      // param.append('access_token', this.token)
      // param.append('company_name', this.companyName)
      // param.append('status', this.value)
      // param.append('inspection_status', this.value1)
      // param.append('platform_id', this.platId)
      // param.append('start_time', this.start_time)
      // param.append('end_time', this.end_time)
      // const { data: res } = await this.$axios({
      //   method: 'post',
      //   url: '/api/SafetyInspectionRecord/output_down',
      //   data: param,
      // })
      // console.log('导出结果', res)
      // window.open(res)
      // if (res.code != 200)
      //   return this.$message.error('导出的条数过多,请缩选中的短时间范围')
      // this.formData1 = res.datas
    },
    handleSearch() {
      this.currentPage = 1
      this.getChangeList()
    },
    // 获取数据
    async getChangeList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSize)
      param.append('page', this.currentPage)
      param.append('company_name', this.companyName)
      param.append('inspection_status', this.value1)
      param.append('status', this.value)
      param.append('platform_id', this.platId)
      param.append('start_time', this.start_time)
      param.append('end_time', this.end_time)
      // param.append('company_name', this.companyName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    // TODO查看详情
    async checkOutDetails(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', this.historyisMine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record_detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData1 = res.datas
      // 巡检图片视频
      this.changeImgCheckList = res.datas.inspection_img
      this.changeVideoCheckList = res.datas.inspection_video
      // 整改图片视频
      this.changeImgList = res.datas.rectify_img
      this.changeVideoList = res.datas.rectify_video
      console.log('整改图片', this.changeImgList)
      // 复查图片视频
      this.changeImgResetList = res.datas.review_img
      console.log('复查图片', this.changeImgResetList)
      this.changeVideoResetList = res.datas.review_video

      this.dialogHistory = false
      this.dialogdh = true
    },
    showPic(url) {
      this.showImgUrl = url
      this.imgDia = true
    },
    showVideo(url) {
      this.showVideoUrl = url
      this.videoDia = true
    },
    // *点击整改记录(获取数据)
    async getHistoryList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSize1)
      param.append('page', this.currentPage1)
      param.append('id', this.historyId)
      param.append('is_mine', this.historyisMine)
      // param.append('company_name', this.companyName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/record',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData1 = res.datas.list
      this.totalCount1 = parseInt(res.datas.count)
    },
    // *点击整改记录
    async changeHistory(row) {
      this.historyId = row.id
      this.historyisMine = row.is_mine
      this.getHistoryList()
      this.dialogHistory = true
    },
    // *点击巡检详情
    async checkOut(row) {
      this.dangerName = row.safety_inspection_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', row.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/SafetyInspectionRecord/detail',
        data: param,
      })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData = res.datas
      this.detailImgList = res.datas.inspection_img
      this.detailVideoList = res.datas.inspection_video
      this.dialogDetail = true
    },
    dialogDetailClose() {
      // 清空图片视频数组
      this.detailImgList = []
      this.detailVideoList = []
      this.dialogDetail = false
    },
    historyDiaClose() {
      this.dialogHistory = false
    },
    imgDiaClose() {
      this.imgDia = false
    },
    videoDiaClose() {
      this.videoDia = false
    },
    dialogdhClose() {
      this.dialogdh = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getChangeList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getChangeList()
    },
    handleSizeChange1(val) {
      this.pageSize1 = val
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val
    },
    async getPlatList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/platform',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.platList = res.datas
      console.log('平台列表', res)
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    if (this.$route.query.inspection_status) {
      this.value1 = parseInt(this.$route.query.inspection_status)
      return this.getChangeList()
    }
    this.getPlatList()
    this.getChangeList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }
    .selectCol {
      // margin-right: 10px;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 20%;
        min-width: 50px;
      }
      /deep/ .el-input {
        width: 85%;
        margin: 0 10px;
        .el-input__inner {
          width: 100%;
          margin: 0;
        }
      }
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      // width: 100%;
      // width: 100px;
      // width: 100px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-input__icon {
      // display: none !important;
      display: flex;
      align-items: center;
    }
    /deep/ .el-select__caret {
      display: none;
    }
    /deep/.el-icon-date {
      display: none;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 15px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  .el-button:nth-child(1) {
    margin-left: 10px;
  }
  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }
  .el-button:nth-child(even) {
    width: 50%;
  }
  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 0;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}
.modifyBtn {
  margin-left: 0 !important;
  background-color: #ec2324;
}
.checkDetails {
  /deep/.el-dialog {
    margin-top: 10vh !important;
  }
  /deep/.el-dialog__body {
    height: 75vh;
    overflow: auto;
    padding-bottom: 0;
  }
}
.imgDia {
  /deep/.el-dialog {
    margin-top: 10vh !important;
  }
  /deep/ .el-dialog__header {
    background: space;
  }
  /deep/.el-dialog__body {
    height: 75vh;
    overflow: auto;
    padding-bottom: 10px;
    text-align: center;
    img {
      width: 80%;
    }
  }
}
.videoDia {
  /deep/.el-dialog {
    margin-top: 10vh !important;
  }
  /deep/ .el-dialog__header {
    background: space;
  }
  /deep/.el-dialog__body {
    // height: 75vh;
    overflow: auto;
    padding-bottom: 50px;
    text-align: center;
    video {
      width: 80%;
    }
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}
/deep/ .el-textarea.is-disabled .isRed {
  color: red !important;
}
/deep/ .el-textarea.is-disabled .isBlue {
  color: blue !important;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
</style>